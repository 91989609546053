import React from 'react';
import {navigate} from "gatsby";
import {connect} from "react-redux";
import qs from "query-string";

import tw, {styled, GlobalStyles} from 'twin.macro';
import Helmet from "react-helmet";

import {PRICE} from "@/constants";
import {iState} from "@/state";
import {iState as iStateForm, reset as resetForm} from "@/state/reducers/form";
import Theme from "@/components/theme";
import Logo from "@/components/illustrations/logo";
import Seo from "@/components/seo";
import {IPageProps} from "@/props";


const Wrapper = styled.div(
	() => [
		tw`bg-gradient-to-b from-primary-800 to-primary-background flex sm:items-center justify-center h-screen px-5`
	]
);

const Error = styled.p(
	() => [
		tw`text-red-500 text-sm`
	]
);

const PayButton = styled.button(
	() => [
		tw`mb-5
		block
		w-full
		flex
		justify-center
		py-3
		px-20
		border
		border-transparent
		text-xl
		font-bold
		rounded-md
		text-secondary_accent-text
		bg-secondary_accent-background
		focus:outline-none
		focus:border-primary-500
		focus:shadow-outline-blue
		active:bg-secondary_accent-background
		transition
		duration-150
		ease-in-out
		mt-4
		shadow-md`
	]
);

interface Props extends IPageProps {
	form: iStateForm;

	resetForm: typeof resetForm;
}

const Create: React.FunctionComponent<Props> = (props: Props) => {
	const qsParsed = qs.parse(location.search);

	const [error, setErorr] = React.useState(false);
	const [success, setSuccess] = React.useState(false);
	const [canceled, setCanceled] = React.useState(false);
	const [formVisible, setFormVisible] = React.useState(false);

	if (!qsParsed.s) {// server id missing
		navigate("/create");
		return <div/>;
	}

	React.useEffect(() => {
		window.Paddle.Setup({vendor: parseInt(`${process.env.PADDLE_VENDOR_ID}`)});

	}, []);

	const buy = () => {
		setFormVisible(true);
		window.Paddle.Checkout.open({
			product        : parseInt(`${process.env.PADDLE_PRODUCT_ID}`),
			email          : props.form.email,
			passthrough    : qsParsed.s,
			successCallback: (data: any) => {
				setSuccess(true);

				if (data.checkout.completed && data.product.id == process.env.PADDLE_PRODUCT_ID) {
					// record sale
					window.gtag('event', 'purchase', {
						"transaction_id": data.checkout.id,
						"value"         : PRICE,
						"currency"      : "USD",
						"tax"           : data.checkout.prices.customer.total_tax,
						"shipping"      : 0,
						"items"         : [{
							"id"  : props.form.service.toLowerCase() == "wireguard" ? "1" : "2",
							"name": props.form.service
						}]
					});


					props.resetForm();
					navigate("/success?s=" + qsParsed.s);
				}
			},
			closeCallback  : (data: any) => {
				setCanceled(true);
			},
		});
	};

	return (
		<Theme>
			<GlobalStyles/>
			<Helmet bodyAttributes={{class: 'font-body'}}/>
			<Seo {...props}/>

			<Wrapper>
				<div className={`text-center`}>
					<div className="mb-6">
						<Logo width={140} className={"m-auto"}/>
					</div>

					{error && <Error>There was an error processing your request</Error>}

					{!formVisible && !success && (
						<div className={`max-w-lg w-full m-auto`}>
							<PayButton onClick={buy.bind(this)}>Buy now for ${PRICE}</PayButton>
						</div>
					)}
				</div>
			</Wrapper>
		</Theme>
	);
};


const mapStateToProps = (state: iState) => {
	return {
		form: state.form,
	};
};

const mapDispatchToProps = {resetForm};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Create);
